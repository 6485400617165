import SButton from '../../../../../../../components/ui/s-button'
import STitle from '../../../../../../../components/ui/s-title'
import { Auth } from '../../../../../../../plugins/firebase'

export default {
  name: 'IdentifiedMode',

  components: {
    SButton,
    STitle,
  },

  computed: {
    isLoggedIn() {
      return Auth.currentUser && !Auth.currentUser.isAnonymous ? true : false
    },
  },

  methods: {
    async submit() {
      this.$emit('setDraft')

      if (!Auth.currentUser || Auth.currentUser.isAnonymous === true) {
        this.$emit('showLoginForm')
      } else {
        this.$emit('submit')
      }
    },
  },
}
